import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import './index.scss';
import 'bulma/bulma.sass';
import styled, { css } from 'styled-components';
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import SEO from "../../../components/SEO";

const box = css`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
`;

const HeroWrapper = styled.div`
  position: relative;
  margin: 0 10%;
  @media (min-width: 1024px) {
    margin: 0 auto;
    max-width: 1024px;
  }
`;

const CameraHero = styled.div`
  ${box};
  position: absolute;
  width: 300px;
  top: -80px;
  left: -130px;
  transform: rotate(-10deg);
  @media (min-width: 1024px) {
    width: 500px;
    top: -200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transform: rotate(-0deg);
  }
`;

const AirportHero = styled.div`
  ${box};
  position: absolute;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 300px;
    top: 160px;
    left: -200px;
  }
`;

const UsingPhoneHero = styled.div`
  ${box};
  position: absolute;
  width: 150px;
  top: -100px;
  right: -50px;
  @media (min-width: 1024px) {
    width: 200px;
    top: 120px;
    right: -120px;
  }
`;

function HeroImages() {
  return (
    <HeroWrapper>
      <CameraHero>
        <StaticImage
          layout='fullWidth'
          loading='eager'
          sizes='(max-width: 768px) 300px, 500px'
          src='./passport-and-camera.jpg'
          alt='A camera and a passport sitting on a desk.'
        />
      </CameraHero>
      <UsingPhoneHero>
        <StaticImage
          layout='fullWidth'
          loading='eager'
          sizes='(max-width: 768px) 150px, 200px'
          src='./using-phone.png'
          alt='Woman using Passport Studio on her phone.'
        />
      </UsingPhoneHero>
      <AirportHero>
        <StaticImage
          layout='fullWidth'
          sizes='300px'
          src='./airport.jpg'
          alt='Seating area at airport.'
        />
      </AirportHero>
    </HeroWrapper>
  );
}

const HeroBodyWrap = styled.div`
  text-align: center;
  margin: 200px auto 0 auto;
  max-width: 350px;
  h2,
  p {
    margin: auto;
    max-width: 90%;
  }
  
  @media (min-width: 768px) {
    max-width: 500px;
  }
  @media (min-width: 1024px) {
    h2,
    p {
      margin: auto;
      max-width: 470px;
    }

    margin: 230px auto 0 auto;
    max-width: 700px;
  }
`;

function InstallButton({ className, children }) {
  return (
    <a
      href='https://play.google.com/store/apps/details?id=one.hpe.passport'
      className={`button is-info ${className}`}
    >
      {children}
    </a>
  );
}

function HeroBody() {
  return (
    <HeroBodyWrap>
      <h2 className='title is-size-3 is-size-2-tablet is-size-1-desktop has-text-weight-medium'>
        Passport photos last ten years
      </h2>
      <p>
        Capture, edit, and print passport photos from home. Passport
        Studio makes it fun and easy to make a beautiful passport photo.
      </p>
      <InstallButton className='mt-6'>
        Install on Android
      </InstallButton>
      <div className='box mt-6'>
        <StaticImage
          layout='fullWidth'
          loading='eager'
          sizes='(max-width: 768px) 300px, (max-width: 1024px) 500px, 700px'
          src='./airport-signs.png'
          alt='Seating area at airport.'
        />
      </div>
    </HeroBodyWrap>
  );
}

function Body() {
  return (
    <main>
      <HeroImages />
      <HeroBody />
    </main>
  );
}

const IndexPage = () => {
  return (
    <>
      <SEO title='Passport Studio' description='Beautiful passport photos. Available on Android.'/>
      <div id='flex-root'>
        <Navbar />
        <Body />
        <Footer>
          <div>
            <p><strong>Passport Studio</strong> by High Point Engineering.</p>
            <p>Washington, USA</p>
          </div>
        </Footer>
      </div>
    </>
  );
};

export default IndexPage;
