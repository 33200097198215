import * as React from "react"

export default function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 86.837 40.395"
      width={props.width ? props.width : "100%"}
      {...props}
    >
      <path
        d="M86.837 36.73c-2.91-3.246-3.895-7.659-5.653-11.534-.543-3.304-4.685-1.627-4.746-5.06-1.087-3.759-4.807-5.174-7.31-7.707-2.24-2.833-2.396-8.463-7.246-8.09-2.68 2.073-5.053 5.054-7.59 7.555-1.395 4.85-5.395 8.41-6.957 13.194-.893 1.409-4.356 4.755-4.712 2.786 2.948-3.362 4.947-7.435 6.765-11.466-1.8-.729-4.823 1.712-5.999 3.53-1.728 2.617-3.165 5.567-5.893 7.316-2.746 2.929-2.8 7.61-6.31 9.958-.69.994-3.79 3.049-1.985.38 1.267-3.377 4.615-8.488.382-10.803-1.817-2.245-4.397-6.68-7.066-2.751-3.015 3.446-5.723 7.228-9.514 9.906-2.914 3.162-6.902 4.89-11.013 5.953-4.121 1.454 1.316-1.898 2.273-3.248 1.998-1.727 3.434-4.05 5.308-5.827 3.627-1.738 6.499-4.453 9.177-7.357 2.608-1.771 5.879-4.92 9.195-3.077 2.432 1.194 5.22 5.509 8.04 3.03 3.667-3.475 6.629-7.988 11.276-10.23 4.142-.215 6.057-3.515 8.24-6.47 2.965-1.707 4.864-7.747 8.737-6.032 3.411 3.046 4.01 8.211 7.68 11.09 2.917 2.437 4.555 6.023 7.474 8.335 2.933 3.35 3.945 7.87 5.757 11.846.6 1.578 1.165 3.168 1.69 4.773z"
        fill={props.fill ? props.fill : "#333"}
      />
    </svg>
  )
}