import React, { useState } from 'react';
import './Navbar.scss';

export default function Navbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <nav
      className='navbar is-black'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='container'>
        <div className='navbar-brand'>
          Passport Studio by
          <a className='navbar-item' href='/'>
            hpe
          </a>
        </div>
      </div>
    </nav>
  );
}
