import React from 'react';
import './Footer.scss';
import Logo from "./Logo";

export default function Footer({children}) {
  return (
    <footer className="footer">
      <div className="container has-text-centered">
        <div className="columns is-align-items-center is-mobile">
          <div className="column is-one-third">
            <Logo/>
          </div>
          <div className="column">
            {children}
          </div>
        </div>
      </div>
    </footer>
  )
}
